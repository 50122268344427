<template>
  <div>
    <v-container fluid>
      <v-row align-content="end">
        <v-col>
          <v-btn @click="newUser()">
            <v-icon left color="grey">mdi-note-plus-outline</v-icon>
            Neu
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="dataTableHeaders"
      :items="users"
      :items-per-page="-1"
      sort-by="username"
      class="elevation-1"
    >
      <template v-slot:item.role="{ item }">
        {{ item.role == 0 ? "Administrator" : "Kunde" }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
        small
        class="mr-2"
        @click="editUser(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "UserList",
  props: ["users"],
  data() {
    return {
      dataTableHeaders: [
        {
          text: "Benutzername",
          value: "username",
          sortable: true,
        },
        {
          text: "Firma",
          value: "company",
          sortable: true,
        },
        {
          text: "Vorname",
          value: "firstName",
          sortable: true,
        },
        {
          text: "Nachname",
          value: "lastName",
          sortable: true,
        },
        {
          text: "Rolle",
          value: "role",
          sortable: true,
        },
        {
          text: "Aktionen",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    editUser(user) {
      this.$router.push({ name: "edit_user", params: {userId: user.id} });
    },
    newUser() {
      this.$router.push({ name: "new_user"});
    }
  },
  components: {},
};
</script>

<style>
</style>
<template>
  <div>
    <app-bar :title="title" />
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col class="col-6">
            <v-text-field
              label="Benutzername"
              v-model="user.username"
              :rules="rules.username"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="E-Mail"
              v-model="user.email"
              :rules="rules.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-6">
            <v-select
              :items="roles"
              label="Rolle"
              :rules="rules.role"
              v-model="user.role"
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              label="Kundennummer"
              v-model="user.customerNumber"
              :rules="rules.customerNumber"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-6">
            <v-text-field
              label="Passwort"
              v-model="user.password"
              :rules="rules.password"
              type="password"
            ></v-text-field>
          </v-col>
          <v-col class="col-6">
            <v-text-field
              label="Passwort wiederholen"
              v-model="user.password_repeat"
              :rules="[rules.passwordMatch]"
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-6">
            <v-text-field
              label="Firma"
              v-model="user.company"
              :rules="rules.company"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col6">
            <v-text-field
              label="Vorname"
              v-model="user.firstName"
              :rules="rules.firstName"
            ></v-text-field>
          </v-col>
          <v-col class="col6">
            <v-text-field
              label="Nachname"
              v-model="user.lastName"
              :rules="rules.lastName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="user.apiKey"
              readonly="true"
              label="API-Key"
            ></v-text-field>
            <v-btn depressed color="primary" @click="generateApiKey()"
              >API-Key generieren</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="saveUser()">
              <v-icon left color="grey">mdi-content-save-outline</v-icon>
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import AppBar from "../components/views/AppBar.vue";
import userService from "../services/UserService";

export default {
  name: "user-details",
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.loadUser();
      },
      { immediate: true }
    );
  },
  data() {
    return {
      user: {},
      rules: {
        passwordMatch: this.passwordMatch,
        password: [
          (v) =>
            v && v.length < 8
              ? "Das Passwort muss mindestens 8 Zeichen lang sein."
              : true,
        ],
        username: [
          (v) => !!v | "Pflichtfeld",
          (v) =>
            (v && v.length > 3) ||
            "Der Benutzername muss mindestens 4 Zeichen lang sein",
        ],
        email: [
          (v) =>
            (v &&
              v.match(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              )) ||
            "Keine gültige E-Mailadresse",
        ],
        role: [(v) => v !== undefined || "Bitte wählen Sie eine Rolle"],
        customerNumber: [
          (v) =>
            (v && v > 0) || "Bitte geben Sie eine gültige Kundennummer ein.",
        ],
        company: [
          (v) => (v && v.length > 2) || "Bitte geben Sie einen Firmennamen ein",
        ],
        firstName: [
          (v) => (v && v.length > 1) || "Bitte geben Sie einen Vornamen ein",
        ],
        lastName: [
          (v) => (v && v.length > 1) || "Bitte geben Sie einen Nachnamen ein",
        ],
      },
      roles: [
        {
          text: "Kunde",
          value: 1,
        },
        {
          text: "Administrator",
          value: 0,
        },
      ],
    };
  },
  computed: {
    title() {
      if (this.user.id) {
        return "Benutzer bearbeiten: " + this.user.username;
      }

      return "Neuer Benutzer";
    },
  },
  methods: {
    loadUser() {
      let vm = this;
      if (this.$route.params.userId) {
        userService.getUser(this.$route.params.userId).then((user) => {
          vm.user = user;
          vm.user.password = "";
          vm.user.password_repeat = "";
        });
      }
    },
    passwordMatch() {
      if (this.user.password && this.user.password_repeat) {
        if (this.user.password != this.user.password_repeat) {
          return "Die Passwörter stimmen nicht überein.";
        }
      }

      return true;
    },
    generateApiKey() {
      let vm = this;

      this.$dialog
        .confirm({
          text: "Wenn Sie einen neuen API-Key generieren ist eine Anmeldung mit dem vorherigen Key nicht mehr möglich. Möchten Sie einen neuen API-Key generieren?",
          title: "API-Key",
          actions: {
            false: "Nein",
            true: "Ja",
          },
        })
        .then((res) => {
          if (res) {
            userService.generateApiKey(this.user.id).then((data) => {
              vm.user.apiKey = data.apiKey;
            });
          }
        });
    },
    saveUser() {
      let vm = this;
      let result = this.$refs.form.validate();

      if (result) {
        if (this.$route.params.userId) {
          userService
            .updateUser(vm.user)
            .then(() => {
              vm.$router.push({ name: "users" });
            })
            .catch(() => {
              vm.$notify({
                title: "Fehler beim Speichern",
                text: "Es ist ein Fehler beim Speichern des Benutzers aufgetreten.",
                type: "error",
              });
            });
        } else {
          userService
            .validateCustomerNumber(vm.user.customerNumber)
            .then((res) => {
              if (res) {
                userService.createUser(vm.user).then(() => {
                  vm.$router.push({ name: "users" });
                });
              } else {
                vm.$notify({
                  title: "Kundennummer schon vergeben.",
                  text: "Bitte vergeben Sie eine neue Kundennummer",
                  type: "error",
                });
              }
            });
        }
      }
    },
  },
  components: {
    AppBar,
  },
};
</script>

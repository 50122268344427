<template>
  <div>
    <app-bar title="Benutzerverwaltung" />
    <UserList :users="users" />
  </div>
</template>

<script>
import AppBar from "../components/views/AppBar.vue";
import UserList from "../components/UserList.vue"
import userService from '../services/UserService'

export default {
  name: "users",
    created() {
    this.$watch(
      () => this.$route.params,
      () => { this.loadUsers(); },
      {immediate: true }
    )
  },
  data() {
    return {
      users: []
    }
  },
  computed: {
  },
  methods: {
    loadUsers() {
      let vm = this;
      userService.getAll().then(users => {
        vm.users = users;
      });
    },

  },
  components: {
    AppBar,
    UserList
  },
};
</script>
